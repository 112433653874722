import React, {useEffect, useState} from "react";
import {Link, useMatch} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import "./NavBar.css";
import LogoWhite from "../../assets/images/logo-white.png";
import LogoBlack from "../../assets/images/logo-black.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TopHeader from "../topheader/TopHeader";

const NavBar = () =>
{
    const [menuOpen, setMenuOpen] = useState(false);
    const [navbarScrolled, setNavbarScrolled] = useState(false);
    const match = !!useMatch("/portal-medico");

    // Define the media query for mobile devices
    const isMobile = useMediaQuery({maxWidth: 767});

    const toggleMenu = () =>
    {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () =>
    {
        setMenuOpen(false);
    };

    useEffect(() =>
    {
        const handleScroll = () =>
        {
            if (window.scrollY > 0)
            {
                setNavbarScrolled(true);
            }
            else
            {
                setNavbarScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="top">
            {!match && <TopHeader/>}
            <div
                className={`wrapper ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid content wrapper">
                        <div className="logo">
                            <Link to="/" className="navbar-brand">
                                <img src={navbarScrolled ? LogoBlack : LogoWhite} alt="Logo"
                                     className="d-inline-block align-text-top"/>
                            </Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false"
                                aria-label="Toggle navigation" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={menuOpen ? "angle-down" : "bars"}
                                             className={`element ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}/>
                        </button>
                        <div className={`collapse navbar-collapse menu ${menuOpen ? "show" : ""}`}
                             id="navbarNavDropdown">
                            <ul className={`navbar-nav submenu element ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                <li className={`nav-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                    <Link to="/" className="nav-link active" onClick={closeMenu}>Início</Link>
                                </li>
                                <li className={`nav-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                    <Link to="/exames" className="nav-link" onClick={closeMenu}>Exames</Link>
                                </li>
                                <li className={`nav-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                    <a href="https://laudostokenurl.com.br/vuemotion-acesso-megascan.php"
                                       className="nav-link" target="_blank" onClick={closeMenu} rel="noreferrer">Resultados
                                        de Exames</a>
                                </li>
                                <li className={`nav-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                    <Link to="/fale-conosco" className="nav-link" onClick={closeMenu}>Fale
                                        Conosco</Link>
                                </li>
                                <li className={`nav-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                    <Link to="/trabalhe-conosco" className="nav-link" onClick={closeMenu}>Trabalhe
                                        Conosco</Link>
                                </li>
                                {/* Dropdown for larger screens */}
                                <li className="nav-item dropdown d-none d-lg-block">
                                    <a className="nav-link" role="button" onClick={toggleMenu}
                                       data-bs-toggle="dropdown"
                                       aria-expanded="false">
                                        <FontAwesomeIcon icon={menuOpen ? "angle-down" : "bars"}
                                                         className={`element ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}/>
                                    </a>
                                    <ul className={`dropdown-menu dropdown-menu-end ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}>
                                        <li className="dropdown-item"><Link to="/sobre"
                                                                            className={`dropdown-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                                                            onClick={closeMenu}>Sobre</Link></li>
                                        <li className="dropdown-item"><Link to="/corpo-clinico"
                                                                            className={`dropdown-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                                                            onClick={closeMenu}>Corpo Clínico</Link>
                                        </li>
                                        <li className="dropdown-item"><Link to="/unidades"
                                                                            className={`dropdown-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                                                            onClick={closeMenu}>Unidades</Link></li>
                                        <li className="dropdown-item"><Link to="/portal-medico"
                                                                            className={`dropdown-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                                                            onClick={closeMenu}>Portal Médico</Link>
                                        </li>
                                    </ul>
                                </li>
                                {/* Dropdown items for smaller screens */}
                                <li className="nav-item d-lg-none">
                                    <Link to="/sobre"
                                          className={`nav-link nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                          onClick={closeMenu}>Sobre</Link>
                                </li>
                                <li className="nav-item d-lg-none">
                                    <Link to="/corpo-clinico"
                                          className={`nav-link nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                          onClick={closeMenu}>Corpo Clínico</Link>
                                </li>
                                <li className="nav-item d-lg-none"><Link to="/unidades"
                                                                         className={`dropdown-item nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                                                         onClick={closeMenu}>Unidades</Link>
                                </li>
                                <li className="nav-item d-lg-none">
                                    <Link to="/portal-medico"
                                          className={`nav-link nav-under ${navbarScrolled ? "scrolled" : (isMobile ? (match ? "transparent" : "red-bg") : "transparent")}`}
                                          onClick={closeMenu}>Portal Médico</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default NavBar;
